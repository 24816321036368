.blue{
    filter: invert(0.5) sepia(1)  saturate(10) hue-rotate(180deg); 
}
  
.turqoise{
  filter: invert(56%) sepia(82%) saturate(376%) hue-rotate(125deg) brightness(83%) contrast(90%);
}
  
.orange{
  filter: invert(68%) sepia(81%) saturate(4119%) hue-rotate(3deg) brightness(104%) contrast(106%);
}